import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as brandHeaderStyles from "./BrandHeader.module.scss"

const BrandHeader = ({}) => {
  return (
    <div className={brandHeaderStyles.wrapper}>
      <div
        className={brandHeaderStyles.heroImageWrapper}
        id="brand-header-hero"
      >
        <StaticImage
          src="../../assets/images/index/brand-headers/lehighOutfitters/lo-banner-mobile.jpg"
          className={brandHeaderStyles.heroImageMB}
          alt=""
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/lehighOutfitters/lo-banner-tablet.jpg"
          className={brandHeaderStyles.heroImageTB}
          alt=""
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/lehighOutfitters/lo-banner-desktop.jpg"
          className={brandHeaderStyles.heroImageDT}
          alt=""
        />
      </div>
      <div className={`brand-container ${brandHeaderStyles.title}`}>
        {/* just a placeholder til dynamic breadcrumb component is built */}
        <h1>Lehigh Outfitters</h1>
        <p>The Greatest Selection of Footwear for Work &amp; Weekends</p>
        <div className={brandHeaderStyles.buttons}>
          <a
            class="CTAblack"
            href="https://www.lehighoutfitters.com/"
            title="Lehigh Outfitters"
          >
            Visit Website
          </a>
          {/* <a class="CTAalt" href="" title="">Watch Brand Video</a> */}
        </div>
      </div>
    </div>
  )
}

export default BrandHeader
