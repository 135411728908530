import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/lehighOutfittersBrandHeader"
import BrandBody from "../../components/brand-bodies/lehighOutfittersBrandBody"

const LehighOutfittersPage = () => {
  const pageTitle = "Lehigh Outfitters"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        Rooted in the Lehigh Valley at the heart of the steel mill industry and
        the rise of workforce safety, Lehigh Outfitters is proud to offer the
        greatest selection of footwear for work &amp; weekends - brands and
        styles to meet any tough environment from heavy industrial to weekend
        warrior.
      </p>
    </>
  )
}

export default LehighOutfittersPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Lehigh Outfitters</title>
    <meta
      name="description"
      content="Rooted in the Lehigh Valley at the heart of the steel mill industry and the rise of workforce safety, Lehigh Outfitters is proud to offer the greatest selection of footwear for work &amp; weekends - brands and styles to meet any tough environment from heavy industrial to weekend warrior."
    />
  </>
)
