import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Lehigh Outfitters</h2>
          <p>Rooted in the Lehigh Valley at the heart of the steel mill industry and the rise of workforce safety, Lehigh Outfitters is proud to offer the greatest selection of footwear for work &amp; weekends - brands and styles to meet any tough environment from heavy industrial to weekend warrior.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-headers/rocky/factory-sepia.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      {/* <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-outdoor-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky outdoor"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-work-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky work"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-western-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky western"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-psd-commil-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky public service and commercial military"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>Superb Style &amp; Protection Selection</h4>
            <p>Lehigh Outfitters offers everything but the kitchen sink in safety work footwear. We fit any foot or environment by providing every combination of protective elements needed in a large selection of styles from athletics to pull-ons, loggers, hikers, western and traditional 6- and 8-inch lace-ups.</p>
            <h4>Exceptional Women's Selection</h4>
            <p>To ensure hard-working women don't have to compromise by wearing men's work boots, we provide an exceptional collection of women's safety footwear built specifically for a woman's foot.</p>
            <h4>QuickFit</h4>
            <p>When you need Safety Footwear fast, this Lehigh collection of styles ships with free overnight delivery. Perfect for New Hires to start next day or as replacement of recently compromised footwear.</p>
          </div>
      </div> */}
      <div className={brandBodyStyles.strategy}>
        <h4>Lehigh Outfitters Strategy</h4>
        <p>The Lehigh Outfitters goal is to provide quality safety footwear and workwear to Men and Women working in the blue collar industries as well as gear for their weekend projects. With a growing list of top-name brands, including Rocky, Georgia Boot, Durango, Michelin, Muck and Lehigh Safety Shoes, our selection of styles and protective features is sure to meet any industry need.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
